import {
    collection,
    doc,
    DocumentData,
    documentId,
    getDoc,
    getDocs,
    orderBy,
    query,
    setDoc,
    where,
} from 'firebase/firestore';

import { db } from 'external/firebase/index';

import { chunkArray, formatErrorMessage } from './utils';

export const getUserProfile = async (userUid: string) => {
    try {
        const docRef = doc(db, 'users', userUid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
        return { ok: false as const, error: { code: 'db/no-document' } };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getGroupIdByGroupCode = async (groupCode: string) => {
    try {
        let groupId;
        const q = query(collection(db, 'groups'), where('code', '==', groupCode));
        const querySnap = await getDocs(q);
        querySnap.forEach(docSnap => {
            groupId = docSnap.id;
        });
        return groupId;
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getGroup = async (group: string) => {
    try {
        const docRef = doc(db, 'groups', group);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            throw new Error('db/no-document');
        }
        return docSnap.data();
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getGroups = async () => {
    try {
        const groups: DocumentData[] = [];
        const q = query(collection(db, 'groups'));
        const querySnap = await getDocs(q);
        querySnap.forEach(docSnap => {
            groups.push({ id: docSnap.id, ...docSnap.data() });
        });
        return groups;
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const updateGroupModules = async (group: string, modules: object) => {
    try {
        const docRef = doc(db, 'groups', group);
        await setDoc(docRef, { modules }, { merge: true });
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getGroupModulesIds = async (group: string) => {
    try {
        const docRef = doc(db, 'groups', group);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            throw new Error('db/no-document');
        }
        return Object.keys(docSnap.data().modules);
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getModule = async (module: string) => {
    try {
        const docRef = doc(db, 'modules', module);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            throw new Error('db/no-document');
        }
        return docSnap.data();
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getModulesIds = async () => {
    try {
        const modulesIds: string[] = [];
        const q = query(collection(db, 'modules'));
        const querySnap = await getDocs(q);
        querySnap.forEach(docSnap => {
            modulesIds.push(docSnap.id);
        });
        return modulesIds;
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getModules = async (moduleIds: string[]) => {
    try {
        const modules: DocumentData[] = [];

        // Split the moduleIds into chunks of 10
        const chunks = chunkArray(moduleIds, 10);

        // Use Promise.all to query all chunks in parallel
        const results = await Promise.all(
            chunks.map(chunk => {
                const q = query(collection(db, 'modules'), where(documentId(), 'in', chunk));
                return getDocs(q);
            }),
        );

        results.forEach(querySnap => {
            querySnap.forEach(docSnap => {
                modules.push({ id: docSnap.id, ...docSnap.data() });
            });
        });

        modules.sort((a, b) => a.id.localeCompare(b.id, undefined, { numeric: true }));

        return modules;
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getModuleLesson = async (module: string, lesson: string) => {
    try {
        // TODO: Change "topics" to "lessons" once DB migrations is done
        const docRef = doc(db, 'modules', module, 'topics', lesson);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            throw new Error('db/no-document');
        }
        return docSnap.data();
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const getGroupModuleLastLessonNumber = async (group: string, module: string) => {
    const docRef = doc(db, 'groups', group);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
        throw new Error('db/no-document');
    }
    const openModuleLessons = docSnap.data().modules[module];
    return Number.isNaN(openModuleLessons)
        ? openModuleLessons
              .split(',')
              .filter(n => n.trim() !== '')
              .map(n => Number(n))
        : Number(docSnap.data().modules[module]);
};

export const getModuleLessons = async (module: string, lessonNumbers: number | number[]) => {
    const lessons: DocumentData[] = [];
    const docRef = doc(db, 'modules', module);
    if (Array.isArray(lessonNumbers)) {
        // Split the moduleIds into chunks of 10
        const chunks = chunkArray(lessonNumbers, 10);

        // Use Promise.all to query all chunks in parallel
        const results = await Promise.all(
            chunks.map(chunk => {
                const q = query(collection(docRef, 'topics'), where('order', 'in', chunk));
                return getDocs(q);
            }),
        );

        results.forEach(querySnap => {
            querySnap.forEach(docSnap => {
                lessons.push({ id: docSnap.id, ...docSnap.data() });
            });
        });
        const orderedLessons = lessonNumbers
            .map(order => lessons.find(lesson => lesson.order === order))
            .filter(lesson => lesson);
        return orderedLessons;
    }
    const q = query(collection(docRef, 'topics'), where('order', '<=', lessonNumbers), orderBy('order'));
    const querySnap = await getDocs(q);
    querySnap.forEach(docSnap => {
        lessons.push({ id: docSnap.id, ...docSnap.data() });
    });
    return lessons;
};
